<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['is_attendance','approval','operation']"
            ref="list"
            :submit_preprocessing="submit_preprocessing"
        >
            <template slot="right_btn">
				<export-table v-if="this.$store.state.page_auth.export" type="pre" :url="'/manage/crm.attendance/dayExcel' | export_form" ></export-table>
            </template>
            <template slot="is_attendance" slot-scope="data">
                <span v-if="data.text=='1'">是</span>
                <span v-else-if="data.text=='0'">否</span>
            </template>
            <template slot="approval" slot-scope="data">
                <span v-for="(item,index) in data.record.approval" :key="index">
                    <a @click="get_approval_details(item)">
                        <span v-if="item.type == 1">请假{{item.num}}</span>
                        <span v-else-if="item.type == 2">出差{{item.num}}</span>
                    </a>
                    <a-divider v-if="index < data.record.approval.length - 1" type="vertical" />
                </span>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="get_details(data.record)">查看详情</a>
            </template>
        </TableList>
        <PopTable
            title="考勤详情"
            :list="details.list"
            :columns="pop_columns"
            :visible.sync="pop_visible"
        ></PopTable>
        <BusinessDetails ref="business_details"></BusinessDetails>
        <LeaveDetails ref="leave_details"></LeaveDetails>
		<AttendanceDataDetails ref="attendance_data_details"></AttendanceDataDetails>
    </div>
</template>

<script>


import TableList from "@/components/TableList";

import BusinessDetails from '@/views/business/index/details'
import LeaveDetails from "@/views/approval/leave/details";
import AttendanceDataDetails from "@/views/attendance_data/index/details";

import { getDayReportForm, getDayReportInfo } from "@/api/attendance";

const columns = [
    {
        title: "考勤时间",
        dataIndex: "date_time",
    },
    {
        title: "姓名",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "department",
    },
    {
        title: "岗位职务",
        dataIndex: "role",
    },
    {
        title: "是否考勤",
        dataIndex: "is_attendance",
        scopedSlots: {
            customRender: 'is_attendance'
        },
    },
    {
        title: "考勤规则",
        dataIndex: "rule",
    },
    {
        title: "最早打卡",
        dataIndex: "earliest",
    },
    {
        title: "最晚打卡",
        dataIndex: "latest",
    },
    {
        title: "打卡次数",
        dataIndex: "num",
    },
    {
        title: "工作时长",
        dataIndex: "duration",
    },
    {
        title: "审批单",
        dataIndex: "approval",
        scopedSlots: {
            customRender: 'approval'
        },
    },
    {
        title: "状态",
        dataIndex: "status",
    },
    // {
    //     title: "校准状态",
    //     dataIndex: "desc",
    // },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];



export default {
    name: "Index",
    components: {
        TableList,
        AttendanceDataDetails,
        BusinessDetails,
        LeaveDetails
    },
    data() {
        return {
            get_table_list: getDayReportForm,
            config: this.$config,
            submit_preprocessing: {
                array_to_string: ['department','role']
            },
            details: {

            },
            columns,
            pop_visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
					},
					{
                        type: "tree-select",
                        name: "role",
                        title: "岗位职务",
                        mode: "default",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "keyword",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
					},
					{
                        type: "select",
                        name: "user_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                        list: this.$config.user_status_list
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
    async created() {
		// 为页面添加缓存
		// this.$keep_route.add(this.$options.name);
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
		});
		// this.$method.get_role().then(res => {
        //     this.form_data_seo.list.forEach(item => {
        //         if (item.name == "role") {
        //             item.list = res;
        //         }
        //     });
        // });
        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "role") {
					item.treeData = res;
				}
			});
		});
    },
    methods: {
        get_details(record) {
            // getDayReportInfo({
            //     data: {
            //         uid: record.uid,
            //         date_time: record.date_time
            //     },
            // }).then(res => {
            //     this.details = res.data;
            //     this.pop_visible = true;
			// })
			this.$refs.attendance_data_details.get_details(record)
        },
        get_approval_details(record) {
            if (record.type == 1) {
                this.$refs.leave_details.get_details(record)
            } else if (record.type == 2) {
                this.$refs.business_details.get_details(record)
            }
        },
        show() {
            this.drawer_visible = true;
        },
        drawer_visible_close() {
            this.drawer_visible = false;
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>